.main-footer{
    color: rgb(188, 217, 221);
    background-color: #212529;
    padding-top:3em;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
}
ul.horizontal-list li {
    display: inline-block;
   
    padding: 0 0.5rem;
 
  }
.seperator{
  display:inline-block;
    color: rgb(188, 217, 221);
  
    
   
}

a {
    color: rgb(150, 195, 225);
    text-decoration: none;
  }
 